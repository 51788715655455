@import "../../assets/style/index.scss";
@import '../../node_modules/tiny-slider/dist/tiny-slider.css';
@import "../../node_modules/aos/dist/aos.css";
@import "../../components/navigation/navigation.scss";
@import "../../components/footer/footer.scss";
//@import "../../components/cookie-bar/cookie-bar.scss";

main {

  .header-section {
    padding: 100px 0;
    min-height: 600px;

    @media(max-width: 991px) {
      padding: 50px 0;
      min-height: unset;
    }

    h1 {
      @media(max-width: 991px) {
        font-size: 48px;
      }
    }
  }

  .about-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 30px;
      }
    }
  }

  .offer-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h3 {
      min-height: 48px;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 30px;
      }
    }
  }


  .opinions-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 30px;
      }
    }
  }

  .divider-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 30px;
      }
    }
  }




  .tns-nav {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    button {
      background-color: #e9e9e9;
      width: 20px;
      height: 20px;
      border: none;
      border-radius: 50%;
      margin: 0 4px;

      &.tns-nav-active {
        background-color: #475c4b;
      }
    }
  }

}

.lum-lightbox.lum-open {
  z-index: 99999;
}


.lum-close-button {
  cursor: pointer;
}

.lum-lightbox {
  background: rgba(#000000, 0.9);
}
